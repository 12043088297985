import React from 'react';
import styled from 'styled-components';
import { breakpoints, gridCss } from '../../styles/layout';
import { OrangeButtonStyle, WhiteButton } from '../buttons';
import { Link } from 'gatsby';
import { rtypeN21bu, rtypeN18r, rtypeT84sb } from '../../styles/typography/presets';
import { colors } from '../../styles/colors';
import routes from '../../routes';


const Title = styled.h2`
  ${rtypeN21bu};
  color: ${colors.orange};
`;

const Message = styled.div`
  ${rtypeT84sb};
`;

const Brief = styled.div`
  ${rtypeN18r};
`;

const Button = styled(Link)`
  ${OrangeButtonStyle};
`;


const Section = styled.section`
  ${gridCss};
  justify-content: center;
  text-align: center;
  padding: 90px 0;
  
  ${Title} {
    grid-column: 2 / span 8;
    margin-bottom: 20px;
  }
  ${Message} {
    grid-column: 2 / span 8;
    margin-bottom: 25px;
  }
  ${Brief} {
    grid-column: 2 / span 8;
    margin-bottom: 30px;
  }
  ${Button} {
    grid-column: 2 / span 8;
  }
  
  @media(min-width: ${breakpoints.lg}) {
    padding: 60px 0 240px;
    ${Title} {
      grid-column: 5 / span 2;
      margin-bottom: 45px;
    }
    ${Message} {
      grid-column: 3 / span 6;
      margin: 0 auto 50px;
      max-width: 800px;
    }
    ${Brief} {
      grid-column: 4 / span 4;
      margin-bottom: 60px;
    }
    ${Button} {
      grid-column: 5 / span 2;
    }
  
  }
`;

export default ({pageData, ...props}) => (
  <Section data-aos={'fade-up'} {...props}>
    <Title>{pageData.contact_title}</Title>
    <Message>{pageData.contact_message}</Message>
    <Brief>{pageData.contact_brief.text}</Brief>
    <Button to={routes.contact}>{pageData.contact_button}</Button>
  </Section>
)