import React from 'react';
import styled from 'styled-components';
import { breakpoints, Container, Section } from '../../styles/layout';
import * as presets from '../../styles/typography/presets';
import { colors } from '../../styles/colors';
import { WhiteButton } from '../buttons';

const Title = styled.h1`
  ${presets.rtypeN21bu};
`;

const Message = styled.div`
  ${presets.rtypeT84sb};
`;

const Image = styled.div`
  img {
    display: block;
    max-width: 100%;
  }
`;

const VideoButton = styled(WhiteButton)``;

const HeroContainer = styled(Container)`
  color: ${colors.white};
  text-align: center;
  background: linear-gradient(to bottom, ${colors.pinkishGrey}, ${colors.purple});
  
  padding: 150px 0 0;
  
  ${Title} {
    grid-column: 2 / span 8;
    margin-bottom: 20px;
    
    opacity: 0.01;
    transform: translateY(10px);
    animation: fade-in-up 2s forwards ease-out 1s;
  }
  
  ${Message} {
    grid-column: 2 / span 8;
    margin-bottom: 30px;
    
    opacity: 0.01;
    transform: translateY(10px);
    animation: fade-in-up 2s forwards ease-out 1.5s;
  }
  
  ${Image} {
    grid-column: 2 / span 8;
    
    opacity: 0.01;
    animation: fade-in 2s forwards ease-out 0.5s;
  }
  
  ${VideoButton} {
    grid-column: 4 / span 4;
    justify-self: center;
    margin-bottom: 20px;
  }
  
  @media(min-width: ${breakpoints.lg}) {
    padding: 260px 0 0;
    ${Title} {
      grid-column: 4 / span 4;
      margin-bottom: 45px;
    }
    
    ${Message} {
      grid-column: 3 / span 6;
      margin-bottom: 60px;
    }
    
    ${VideoButton} {
      margin-bottom: 37px;
    }
  }
  
`;

const HeroSection = styled(Section)`
`;


const HomeHero = (props) => {
  const { title, message, background, videoButton } = props;
  return (
    <HeroSection {...props}>
      <HeroContainer>
        <Title>
          {title}
        </Title>
        <Message>{message}</Message>
        {/*<VideoButton>{videoButton}</VideoButton>*/}
        <Image>
          <img src={background.url} alt={''}/>
        </Image>
      </HeroContainer>
    </HeroSection>
  );
};


export default HomeHero;
