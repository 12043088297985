import React from 'react';
import styled from 'styled-components';
import { breakpoints, gridCss } from '../../styles/layout';
import TextBlock from '../shared/TextBlock';
import { colors } from '../../styles/colors';


const Image = styled.div`
  img {
    max-width: 100%;
  }
`;

const CustomTextBlock = styled(TextBlock)`

`;


const Block = styled.div`

`;


const Container = styled.section`
  background: linear-gradient(to bottom, ${colors.purple}, ${colors.pink}, ${colors.orange}, ${colors.yellow});
  padding: 60px 0;

  position: relative;
  &:before {
    content: '';
    position: absolute;
    z-index: 0;
    background: ${colors.white};
    top: 0;
    left: 6px;
    right: 6px;
    bottom: 0;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  ${Block} {
    ${gridCss};
    position: relative;
    z-index: 1;

    margin-bottom: 60px;
    &:last-child {
      margin-bottom: 0;
    }

    ${Image} {
      grid-column: 2 / span 8;
      margin-bottom: 30px;
    }

    ${CustomTextBlock} {
      grid-column: 2 / span 8;
    }

  }

  @media(min-width: ${breakpoints.lg}) {
    padding: 148px 0;

    &:before {
      left: 20px;
      right: 20px;
    }

    ${Block} {
      align-items: center;

      margin-bottom: 210px;
      &:last-child {
        margin-bottom: 0;
      }

      &:nth-child(2n+1) {
        ${CustomTextBlock} {
          grid-column: 2 / span 3;
          grid-row: 1;
        }
        ${Image} {
          grid-column: 6 / span 4;
          grid-row: 1;
        }

      }
      &:nth-child(2n+2) {
        ${CustomTextBlock} {
          grid-column: 7 / span 3;
          grid-row: 1;
        }
        ${Image} {
          grid-column: 2 / span 4;
          grid-row: 1;
        }

      }
    }

    @media(min-width: ${breakpoints.hh}) {
      &:before {
        left: 40px;
        right: 40px;
      }
    }
  }
`;


export default ({pageData}) => (
  <Container>
    {pageData.content_blocks.map((block, index) => (
      <Block data-aos={'fade-up'} key={block.title}>
        <Image>
          <img src={block.image.url} alt={block.image.alt}/>
        </Image>
        <CustomTextBlock
          title={block.title}
          message={block.message}
          brief={block.brief.text}
          buttonLink={block.button_link.url}
          buttonText={block.button_text}
          secondaryButtonLink={block.secondary_button_link?.url}
          secondaryButtonText={block.secondary_button_text}
          secondaryColor={index % 2 === 0 ? colors.purple : colors.orange}
        />
      </Block>
    ))}
  </Container>
)
