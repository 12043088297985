import React from 'react';
import HomeHeroSection from '../components/home/HomeHero';
import { Helmet } from 'react-helmet';
import { descriptionTags, imageTags, titleTags } from '../utilities/head-tags';
import Layout from '../components/layouts';
import { graphql } from 'gatsby';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ContentBlocksSection from '../components/home/ContentBlocksSection';
import VisionSection from '../components/home/VisionSection';
import ContactSection from '../components/home/ContactSection';


class IndexPage extends React.Component {
  componentDidMount() {
    AOS.init({
      duration: 1200,
      easing: 'ease-out', // default easing for AOS animations
    });
    window.analytics && window.analytics.page('Home', {
      title: `${this.props.data.prismicSite.data.name} | ${this.props.data.prismicHomePage.data.hero_message}`,
      url: window.location.href,
      path: window.location.pathname,
    });
  }

  render() {
    const props = this.props;
    const siteData = props.data.prismicSite.data;
    const pageData = props.data.prismicHomePage.data;

    return (
      <Layout reverseLogo>
        <>
          <Helmet>
            {titleTags(`${siteData.name} | ${pageData.hero_message}`)}
            {descriptionTags(pageData.hero_brief)}
            {imageTags(pageData.hero_background.url)}
            <meta name="google-site-verification" content="jHwWYnGeVswk-rujGPRxXzZpuq9-swB6idzWYCFRpYE"/>
          </Helmet>
          <HomeHeroSection title={pageData.hero_title}
                           message={pageData.hero_message}
                           background={pageData.hero_background}
                           videoButton={pageData.video_button}
          />
          <ContentBlocksSection pageData={pageData}/>
          <VisionSection pageData={pageData}/>
          <ContactSection pageData={pageData}/>
        </>
      </Layout>
    );
  }
}

export default IndexPage;

export const query = graphql`
  query HomeQuery {
    prismicSite {
      data {
        name
      }
    }
    prismicHomePage {
      dataString
      data {
        hero_title
        hero_message
        hero_background {
          url
        }
        video_button
        content_blocks {
          title
          message
          brief {
            html
            text
          }
          button_link {
            url
          }
          button_text
          secondary_button_link {
            url
          }
          secondary_button_text
          image {
            url
            alt
          }
        }

        vision_title
        vision_message
        vision_brief {
          text
          html
        }

        contact_title
        contact_message
        contact_brief {
          text
          html
        }
        contact_button
      }
    }
  }
`;
