import React from 'react';
import styled from 'styled-components';
import TextBlock from '../shared/TextBlock';
import { colors } from '../../styles/colors';
import { breakpoints, gridCss } from '../../styles/layout';
import visionImage from '../../assets/images/vision_image.jpg';


const CustomTextBlock = styled(TextBlock)``;

const Image = styled.div``;

const Section = styled.section`
  ${gridCss};
  margin-top: -10px;
  grid-template-rows: 70px auto 300px;
  background: linear-gradient(to bottom, ${colors.yellow}, ${colors.white});

  position: relative;
  &:before {
    content: '';
    position: absolute;
    z-index: 0;
    background-color: ${colors.white};
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: bottom center;
    top: 0;
    left: 6px;
    right: 6px;
    bottom: 0;
  }
  
  ${Image} {
    grid-row: 1 / span 3;
    grid-column: 1 / span 10;
    position: relative;
    z-index: 1;
    padding: 0 6px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    img {
      width: 100%;
    }
  }
  
  ${CustomTextBlock} {
    grid-row: 2;
    position: relative;
    z-index: 2;
    grid-column: 2 / span 8;
  }
  
  @media(min-width: ${breakpoints.lg}) {
    grid-template-rows: 50px auto 540px;
    
    &:before {
      left: 20px;
      right: 20px;
    }
    ${Image} {
      padding: 0 20px;
    }
    
    ${CustomTextBlock} {
      grid-column: 2 / span 4;
    }
  }
  
  @media(min-width: ${breakpoints.hh}) {
    grid-template-rows: 50px auto 900px;
    &:before {
      left: 40px;
      right: 40px;
    }
    ${Image} {
      padding: 0 40px;
    }
    
  }
`;

export default ({ pageData, ...props }) => (
  <Section {...props}>
    <CustomTextBlock
      data-aos={'fade-up'}
      title={pageData.vision_title}
      message={pageData.vision_message}
      brief={pageData.vision_brief.text}
      secondaryColor={colors.yellow}
    />
    <Image data-aos={'fade-up'}>
      <img src={visionImage}/>
    </Image>
  </Section>
)